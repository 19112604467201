<template>
  <section class="index-section s-banner subscribe-banner">
    <div class="container">
      <div
        v-if="state == 1"
        class="banner-form banner-inner bg-light display--flex"
      >
        <div class="banner-form--meta">
          <div class="banner-title">Подпишитесь на обновления</div>
          <div class="banner-text">
            Уникальная информация от лидера в области развития эмоционального
            интеллекта. Ничего лишнего. Примерно 2—3 письма в месяц.
          </div>
          <private-checkboxes
            class="mt-5"
            @checkboxchange="(data) => (checkboxes = data)"
          />
        </div>
        <div class="banner-form--form">
          <form @submit.prevent>
            <div class="form-group">
              <input
                type="text"
                placeholder="Имя*"
                class="form-control"
                v-model="name"
                error="name"
                required
              />
              <errors :errors="errors" field="name"></errors>
            </div>
            <div class="form-group">
              <input
                type="email"
                placeholder="E-mail*"
                class="form-control"
                error="email"
                v-model="email"
                name="email"
                autocomplete="on"
                required
              />
              <errors :errors="errors" field="email"></errors>
            </div>

            <div class="form-action btn-action display--flex">
              <button
                :disabled="!checkboxes.oferta || !checkboxes.personalData"
                @click="sendData"
                type="submit"
                class="btn btn-accent"
              >
                Оформить подписку
              </button>
            </div>
          </form>
        </div>
      </div>

      <div
        v-if="state == 2"
        class="banner-form banner-inner bg-light display--flex"
      >
        <div class="banner-form--meta">
          <div class="banner-title success">Спасибо за подписку</div>
          <div class="banner-text">
            Письма будут приходить, когда будут новые новости
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "@vue/reactivity";
import useErrors from "../../../mixins/useErrors";
import standartError from "../../../mixins/standartError";
import Errors from "../../technical/Errors.vue";
import { useReCaptcha } from "vue-recaptcha-v3";
import { useStore } from "vuex";
import PrivateCheckboxes from "../../form-elements/private-checkboxes/PrivateCheckboxes.vue";
import ymTrigger from "@/components/pop-up-form/ymTrigger";
import {getCurrentInstance} from "vue";

export default {
  components: { Errors, PrivateCheckboxes },
  name: "subscribe-block",
  setup() {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha(),
      { errors, setErrors, clearCustomErrors } = useErrors(),
      store = useStore();
    const app = getCurrentInstance();

    const checkboxes = ref({
      oferta: false,
      personalData: false,
      ad: false,
    });

    let name = ref(""),
      email = ref(""),
      state = ref(1);

    let sendData = async () => {
      await recaptchaLoaded();
      const token = await executeRecaptcha("help");

      let data = {
        name: name.value,
        email: email.value,
        recaptcha_token: token,
        advert_accept: checkboxes.value.ad,
      };

      store
        .dispatch("sendSubscribeRequest", data)
        .then(() => {
          state.value = 2;
          if (app.appContext.config.globalProperties.$metrika) {
            app.appContext.config.globalProperties.$metrika.reachGoal(ymTrigger('subscription'));
          }
        })
        .catch(standartError(setErrors, clearCustomErrors));
    };

    return {
      name,
      email,
      sendData,
      state,
      errors,
      setErrors,
      clearCustomErrors,
      checkboxes,
    };
  },
};
</script>

<style scoped>
.banner-inner {
  padding: 50px;
  background-image: url(./../../../assets/images/hero.svg);
  background-repeat: no-repeat;
  background-position: right -300px center;
  border-radius: 10px;
}

.banner-form {
  align-items: center;
}

.banner-form--meta {
  flex: 0 1 50%;
  margin-right: 40px;
}

.banner-form--form {
  flex: 1;
}

.banner-title {
  letter-spacing: 0;
  text-align: left;
}

.banner-title.success {
  padding-bottom: 58px;
}

.banner-title span {
  display: block;
}

.banner-form .banner-title {
  font-size: 46px;
  line-height: 51px;
}

.banner-text {
  letter-spacing: 0;
  text-align: left;
}

.banner-form .banner-text {
  margin-top: 7px;
  font-size: 18px;
  line-height: 22px;
}

.banner-form--form form {
  max-width: 350px;
}

@media (max-width: 747px) {
  .banner-inner {
    padding: 20px;
  }

  .banner-form {
    flex-direction: column;
  }

  .banner-form .banner-title {
    font-size: 28px;
    line-height: 34px;
  }

  .banner-form .banner-text {
    font-size: 18px;
    line-height: 22px;
  }

  .banner-form--meta {
    margin: 0 0 20px 0;
  }

  .banner-form--form {
    width: 100%;
  }
}
</style>