<template>
  <section class="index-section s-banner">
    <div class="container">
      <div class="banner-lg banner-inner bg-light display--flex flex-column">
        <div class="banner-title">
          <span>Оцените уровень</span>
          <span>ЭИ-зрелости Вашей компании</span>
        </div>
        <div class="banner-text">
          Займет ~ 10
          минут
        </div>
        <div class="btn-action display--flex">
          <router-link to="/testing" class="btn btn-accent">
            Пройти опрос
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "rate-the-level-block",
  setup() {},
};
</script>

<style scoped>
.banner-inner {
  padding: 50px;
  background-image: url(./../../../assets/images/hero.svg);
  background-repeat: no-repeat;
  background-position: right -300px center;
  border-radius: 10px;
}

.banner-title {
  letter-spacing: 0;
  text-align: left;
}

.banner-title span {
  display: block;
}

.banner-lg .banner-title {
  font-size: 62px;
  line-height: 60px;
}

.banner-text {
  letter-spacing: 0;
  text-align: left;
}

.banner-lg .banner-text {
  margin-top: 24px;
  font-size: 24px;
  line-height: 29px;
}

.banner-lg .btn-action {
  margin-top: 24px;
}

@media (max-width: 747px) {
  .banner-inner {
    padding: 20px;
  }

  .banner-lg .banner-title,
  .banner-form .banner-title {
    font-size: 28px;
    line-height: 34px;
  }

  .banner-lg .banner-text,
  .banner-form .banner-text {
    font-size: 18px;
    line-height: 22px;
  }
}
</style>